// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-donator-js": () => import("./../../../src/pages/donator.js" /* webpackChunkName: "component---src-pages-donator-js" */),
  "component---src-pages-donera-js": () => import("./../../../src/pages/donera.js" /* webpackChunkName: "component---src-pages-donera-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mottagare-js": () => import("./../../../src/pages/mottagare.js" /* webpackChunkName: "component---src-pages-mottagare-js" */),
  "component---src-pages-newpassword-js": () => import("./../../../src/pages/newpassword.js" /* webpackChunkName: "component---src-pages-newpassword-js" */),
  "component---src-pages-nointernet-js": () => import("./../../../src/pages/nointernet.js" /* webpackChunkName: "component---src-pages-nointernet-js" */),
  "component---src-pages-nudgewall-js": () => import("./../../../src/pages/nudgewall.js" /* webpackChunkName: "component---src-pages-nudgewall-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */)
}

